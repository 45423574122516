import {
    UserPlus,
    Truck,
    CreditCard,
    Clipboard,
    MapPin,
    Briefcase,
    Package,
    Map,
    FileText,
    Compass
} from 'react-feather';

export const MENUITEMS = [
    /* {
        title: 'Estadísticas', icon: BarChart, type: 'link', badgeType: 'primary', path: '/estadisticas', active: false
    }, */
    // {
    //     perm: 42, title: 'Carta porte', icon: FileText, type: 'sub', badgeType: 'primary', path: '/carta-porte', active: false,
    //     children: [
    //         { perm: 42, path: '/carta-porte', title: 'Carta Porte', type: 'link' },
    //         { perm: 42, path: '/carta-porte/detalle', title: 'Carta Porte Detalle', type: 'link' },
    //         { perm: 42, path: '/carta-porte/nuevo', title: 'Carta Porte Nuevo', type: 'link' },
    //     ]
    // },
    {
        perm: 9, title: 'Clientes', icon: CreditCard, type: 'sub', badgeType: 'primary', path: '/clientes', active: false, children: [
            { perm: 9, path: '/clientes', title: 'Clientes', type: 'link' },
            { perm: 10, path: '/clientes/nuevo', title: 'Nuevo Cliente', type: 'link' },
        ]
    },
    {
        perm: 13, title: 'Cobertura', icon: MapPin, type: 'sub', badgeType: 'primary', path: '/definir-cobertura', active: false,
        children: [
            { perm: 13, path: '/definir-cobertura', title: 'Definir cobertura', type: 'link' },
            { perm: 14, path: '/definir-zonas', title: 'Definir zonas', type: 'link' }
        ]
    },
    {
        perm: 28, title: 'Drivin', icon: Compass, type: 'sub', badgeType: 'primary', path: '/drivin', active: false,
        children: [
            { perm: 28, path: '/vehiculos', title: 'Vehiculos', type: 'link' },
            { perm: 31, path: '/conductores', title: 'Conductores', type: 'link' },
        ]
    },
    {
        perm: 5, title: 'Empresa', icon: Briefcase, type: 'sub', badgeType: 'primary', path: '/empresa', active: false, children: [
            // { perm: 5, path: '/empresa', title: 'Empresas', type: 'link' },
            // { perm: 6, path: '/empresa/nueva', title: 'Nueva Empresa', type: 'link' },
            { perm: 7, path: '/empresa/editar', title: 'Editar empresa', type: 'link' },
            { perm: 8, path: '/empresa/datos-fiscales', title: 'Datos fiscales', type: 'link' },
        ]
    },
    {
        perm: 19, title: 'Envíos', icon: Map, type: 'sub', badgeType: 'primary', path: '/envios', active: false, children: [
            { perm: 19, path: '/envios', title: 'Envíos', type: 'link' },
            // { perm: 20, path: '/envios/nuevo', title: 'Nuevo Envío', type: 'link' },
        ]
    },
    {
        perm: 45, title: 'Facturación', icon: FileText, type: 'sub', badgeType: 'primary', path: '/facturas', active: false,
        children: [
            { perm: 45, path: '/facturas', title: 'Facturas', type: 'link' },
        ]
    },
    {
        perm: 25, title: 'Guías prepagadas', icon: FileText, type: 'sub', badgeType: 'primary', path: '/guias-prepagadas', active: false,
        children: [
            { perm: 25, path: '/guias-prepagadas', title: 'Guías prepagadas', type: 'link' },
            { perm: 26, path: '/guias-prepagadas/nuevo', title: 'Nuevo paquete de guías', type: 'link' },
            { perm: 27, path: '/guias-prepagadas/promociones', title: 'Promociones', type: 'link' },
        ]
    },
    {
        perm: 45, title: 'Instaladores', icon: FileText, type: 'sub', badgeType: 'primary', path: '/instaladores', active: false,
        children: [
            { perm: 45, path: '/instaladores', title: 'Instaladores', type: 'link' },
            // { perm: 45, path: '/instaladores/detalle', title: 'Instaladores Detalle', type: 'link' },
            { perm: 45, path: '/instaladores/nuevo', title: 'Instaladores Nuevo', type: 'link' },
        ]
    },
    {
        perm: 36, title: 'Plantillas', icon: Clipboard, type: 'sub', badgeType: 'primary', path: '/plantillas', active: false, children: [
            // { perm: 5, path: '/empresa', title: 'Empresas', type: 'link' },
            // { perm: 6, path: '/empresa/nueva', title: 'Nueva Empresa', type: 'link' },
            { perm: 36, path: '/plantillas', title: 'Plantillas', type: 'link' },
            { perm: 37, path: '/plantillas/nuevo', title: 'Nueva plantilla', type: 'link' },
        ]
    },
    {
        perm: 34, title: 'Reportes de caja', icon: Clipboard, type: 'sub', badgeType: 'primary', path: '/apertura-caja', active: false,
        children: [
            { perm: 34, path: '/apertura-caja', title: 'Apertura de caja', type: 'link' },
            { perm: 35, path: '/corte-caja', title: 'Corte de caja', type: 'link' },
        ]
    },
    // {
    //     perm: 39, title: 'Rutas', icon: MapPin, type: 'sub', badgeType: 'primary', path: '/rutas', active: false,
    //     children: [
    //         { perm: 39, path: '/rutas', title: 'Rutas', type: 'link' },
    //         { perm: 41, path: '/rutas/rutas_detalle', title: 'Detalle', type: 'link' }
    //     ]
    // },
    {
        perm: 15, title: 'Servicios', icon: Package, type: 'sub', badgeType: 'primary', path: '/servicios', active: false, 
        children: [
            { perm: 15, path: '/servicios', title: 'Servicios', type: 'link' },
            { perm: 16, path: '/servicios/nuevo', title: 'Nuevo servicio', type: 'link' },
            { perm: 24, path: '/servicios/parametros', title: 'Servicios parametros', type: 'link' },
        ]
    },
    {
        perm: 17, title: 'Solicitudes', icon: Clipboard, type: 'link', badgeType: 'primary', path: '/solicitudes', active: false,
        // children: [
        //     { path: '/definir-cobertura', title: 'Definir cobertura', type: 'link' },
        //     { path: '/definir-zonas', title: 'Definir zonas', type: 'link' }
        // ]
    },
    {
        perm: 11, title: 'Sucursales', icon: Truck, type: 'sub', badgeType: 'primary', path: '/sucursales', active: false, children: [
            { perm: 11, path: '/sucursales', title: 'Sucursales', type: 'link' },
            { perm: 12, path: '/sucursales/nuevo', title: 'Nueva sucursal', type: 'link' },
        ]
    },
    {
        perm: 1, title: 'Usuarios CMS', icon: UserPlus, type: 'sub', badgeType: 'primary', path: '/usuarios-cms', active: false, children: [
            { perm: 1, path: '/usuarios-cms', title: 'Usuarios', type: 'link' },
            { perm: 4, path: '/usuarios-cms/nuevo', title: 'Nuevo usuario', type: 'link' },
        ]
    },
    {
        perm: 22, title: 'Usuarios TPV', icon: UserPlus, type: 'sub', badgeType: 'primary', path: '/usuarios-tpv', active: false, children: [
            { perm: 22, path: '/usuarios-tpv', title: 'Usuarios TPV', type: 'link' },
            { perm: 23, path: '/usuarios-tpv/nuevo', title: 'Nuevo usuario TPV', type: 'link' },
        ]
    },

    // {
    //     perm: 19, title: 'Envíos', icon: Map, type: 'link', badgeType: 'primary', path: '/envios', active: true, 
    //     // children: [
    //     //     { perm: 19, path: '/envios', title: 'Envíos', type: 'link' },
    //     // ]
    // },
    
    // {
    //    perm: 1 , title: 'Catálogos Fiscales', icon: CreditCard, type: 'sub', badgeType: 'primary', path: '/empresas', active: false, children: [
    //         { path: '/catalogo', title: 'Catálogo 1', type: 'link' },
    //         { path: '/catalogo/cat1', title: 'Catálogo 2', type: 'link' },
    //     ]
    // },
    // {
    //     title: 'Rutas', icon: MapPin, type: 'sub', badgeType: 'primary', path: '/rutas', active: false, 
    //     children: [
    //         { perm: 1, path: '/rutas', title: 'Definir cobertura', type: 'link' },
    //         { perm: 1, path: '/rutas/detalle', title: 'Definir zonas', type: 'link' }
    //     ]
    // },

]
