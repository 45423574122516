import Dropzone from 'react-dropzone-uploader'
import {useState, useEffect} from 'react'
import Tools from '../../js/tools'

const Default = ({
    onImg,
    inputContent,
    maxFiles,
    initialFiles,
    fileName,
}) => {
    const [initialFilesBase64, setInitialFilesBase64] = useState([])
    const handleChangeStatus = ({ meta, file }, status) => {
        if(status === 'done' || status === 'removed'){
            if(onImg)
                onImg({meta, file: status === 'done'?file:null, status});
        }
    }

    useEffect(() => {
        if(initialFiles !== null && initialFiles !== undefined){
            Tools.urlToObject(initialFiles, fileName)
            .then((response) => {
                setInitialFilesBase64([response])
            })
        }
    }, [initialFiles])
 
    return <Dropzone
        initialFiles={initialFilesBase64}
        onChangeStatus={handleChangeStatus}
        maxFiles={!maxFiles?1:maxFiles}
        inputContent={!inputContent?"Da click o arrastra una imagen png ó jpg":(inputContent?inputContent:'')}
        submitButtonDisabled={files => !maxFiles?files.length === 1:files.length >= maxFiles}
    />
}

export default Default