import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import notie from 'notie'
import CryptoJS from 'crypto-js';

const fechas = {
    '01': 'enero',
    '02': 'febrero',
    '03': 'marzo',
    '04': 'abril',
    '05': 'mayo',
    '06': 'junio',
    '07': 'julio',
    '08': 'agosto',
    '09': 'septiembre',
    '10': 'octubre',
    '11': 'noviembre',
    '12': 'diciembre',
}

const send2 = ( dir , data, method , callback) => {
    fetch(dir, {
        method,
        body: JSON.stringify(data),
        headers:{'Content-Type': 'application/json'}
    })
    .then((response) => {
        if(response.status === 200){
            return response.json()
        }else if(response.status === 404){
            return response.text()
        }
    })
    .then((response) => {
        callback(response);
    })
    .catch((error) => {
        callback({ estado: 0 });
    })
}

const send = async ( dir, data ) => {
    if(!data)
        data={};
    let response = await fetch(dir, {
        method: 'POST',
        body: JSON.stringify(data),
        headers:{'Content-Type': 'application/json'}})
    let res = await response.json()
    return res;
}

const toolFetch =  (objeto, callback) => {
    // const history = useHistory(); ;charset=utf-8
    if(objeto.method === 'GET'){
        let parametros = '', body = objeto.body? objeto.body: null
        
        if(body){
            Object.keys(body).map((v, i) => {
                if(i === 0){
                    parametros += `?${v}=${body[v]}`
                }else{
                    parametros += `&${v}=${body[v]}`
                }
            })
        }

        fetch(`/${objeto.ruta}${body?parametros:''}`, {
            method: objeto.method,
            headers:{'Content-Type': 'application/json'}
        })
        .then(async(response) => {
            if(response.status === 200){
                let res = await response.json()
                
                if(res.estatus === 'SIN-SESION'){
                    return window.location.href = '/login'
                }

                if(res.estatus === 'ERROR'){
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                        // stay: Boolean, // optional, default = false
                        time: 3, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                return callback(res);
            }else if(response.status === 404){
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
        })
        .catch((err) => {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }else{
        fetch(`/${objeto.ruta}`, {
            method: objeto.method,
            body: JSON.stringify(objeto.body),
            headers:{'Content-Type': 'application/json'}
        })
        .then(async(response) => {
            if(response.status === 200){
                let res = await response.json()
                if(res.estatus === 'SIN-SESION'){
                    return window.location.href = '/login'
                }

                if(res.estatus === 'ERROR'){
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                        // stay: Boolean, // optional, default = false
                        time: 3, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                return callback(res);
            }else if(response.status === 404){
                // return getHistory().replace('/login')
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
        })
        .catch((err) => {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }
}

const pathData = () => {
    // if(locationState[window.location.pathname])
    //     return locationState[window.location.pathname];
    // else 
    //     getHistory().push('/inicio');
    // return {};
}

const format = (x) => {
    return new Intl.NumberFormat().format(Number.parseFloat(x).toFixed(2));
}

const toFixed2 = (dato) => {
    if(dato && dato !== ''){
        return dato.toFixed(2)
    }else{
        return '0.00'
    }
}

const toFixed = (dato, decimales = 2) => {
    if(dato && dato !== ''){
        return dato.toFixed(decimales)
    }else{
        return '0.00'
    }
}

const notNull = (dato) => {
    if(dato && dato !== ''){
        return dato
    }else{
        return '0'
    }
}

const toZero = (dato) => {
    return dato.toString().padStart(7,"0000000")
}

const dateFormat = (dato) => {
    let date = dato.substr(0, dato.length - 1).split('T'),
        time =  date[1].substr(0, 8)
    return `${date[0]}, ${time}`
}

const formatFechaLargaCompleta = () => {
    let date = new Date()
    let options = { 
        day: 'numeric', 
        month: 'long', 
        year: 'numeric' 
    };

    let formattedDate = date.toLocaleDateString('es-ES', options);
    let parts = formattedDate.split(' de ');
    formattedDate = `${parts[0]} de ${parts[1]} del ${parts[2]}`;
    return formattedDate;
    
    
}

const urlFormat = (url) => {
    return url? url.toLowerCase().replace(/(%)/gi, '%25').replace(/(#)/gi, '%23').replace(/(-)/gi, '_').replace(/(\ )/gi, '-')
    .replace(/(\/)/gi, '___').replace(/(á)/gi, 'a').replace(/(é)/gi, 'e')
    .replace(/(í)/gi, 'i').replace(/(ó)/gi, 'o').replace(/(ú)/gi, 'u')
    .replace(/(ñ)/gi, 'n').replace(/(\+)/gi, '__').toLowerCase(): ''
}

const urlClear = (url) => {
	// return url.replace(/(\-)/gi, ' ').replace(/(\___)/gi, '/').replace(/(\__)/gi, '+').replace(/(\_)/gi, '.').replace(/(\')/gi, ` `)
    if(url){
        return url.replace(/(\-)/gi, ' ').replace(/(\___)/gi, '/').replace(/(\__)/gi, '+').replace(/(\_)/gi, '-')
    }else{
        return ''
    }
}

const getGrafica = (data, label) => {
    let productos = data[1],
        descripcion = data[0].map((v) => v.DESCRIPCION_PRODUCTO),
        color = data[0].map((v) => v.COLOR_PRODUCTO),
        mat = [[label, ...descripcion]], maxRows = 0, indice = 0, maxColumns = productos.length + 1

    productos.map((v, i) => {
        if(i === 0){
            maxRows = v.length
        } else if(maxRows < v.length){
            maxRows = v.length
            indice = i
        }
    })

    for(let i = 1; i < maxRows + 1; i++){
        mat.push([])
        for(let j = 0; j < maxColumns; j++){
            if(j === 0 ){
                mat[i].push(productos[indice][i - 1].FECHA)
            }else{
                mat[i].push(0)
            }
        }
    }

    for(let i = 0; i < productos.length; i++){
        for(let j = 1; j < mat.length; j++){
            if(productos[i].length > 0){
                if(productos[i].find((v) => mat[j][0] === v.FECHA)){
                    mat[j][i + 1] = productos[i].find((v) => mat[j][0] === v.FECHA).IMPORTE
                }
            }
        }
    }

    return { color, mat }
}

const swalModal = (objeto, callback) => {
    Swal.fire({
        title: objeto.title,
        text: objeto.text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: objeto.confirmButtonText,
        cancelButtonText: objeto.cancelButtonText
    }).then((result) => {
        if(result.value){
            callback({ estado: 1 })
        }else{
            callback({ estado: 0 })
        }
    })
}

// const getForm = (idForm, options = { ignore: [], active: 0, claveEtiquetas: '', alertLabel: {}}) => {
//     if (idForm === '' || idForm === null || idForm === undefined) {
//         return { status: 0 }
//     }

//     const elemet = document.getElementById(idForm)
//     let objectReturn = { status: 0, data: {}, formIsEmpty: 0, labelIsEmpty: {}, stringIsEmpty: '', keyIsEmpty: {}, documentForm: elemet, keys: [], }

//     if (!elemet) {
//         return { status: 0, data: {}, formIsEmpty: 0, labelIsEmpty: {}, keyIsEmpty: {} }
//     }

//     const form = new FormData(elemet)
//     const data = {}
//     const alertLabel = options.alertLabel || {}
//     const ignore = JSON.stringify(options.ignore ? options.ignore : '')
//     objectReturn = {
//         ...objectReturn,
//         formData: form,
//         status: 1
//     }

//     if (options.active) {
//         return objectReturn
//     }

//     for (let key of form.keys()) {
//         let dataValue = document.getElementById((key)).dataset['value']
//         if(!(dataValue == null || dataValue == undefined || dataValue == '')){
//             form.set(key, dataValue)
//         }

//         if (form.get(key) === '') {
//             if (!ignore.includes(key)) {
//                 // if(dataValue == null || dataValue == undefined || dataValue == ''){
//                     objectReturn.formIsEmpty += 1
//                     objectReturn.keyIsEmpty[key] = true
//                 // }
//             }
//         }

//         let docKey = null

//         try {
//             docKey = elemet.querySelector(`[name="${key}"]`) || elemet.getElementById(key)
//             let etiqueta = document.querySelector('.'+key)
//             if(etiqueta){
//                 etiqueta.style['display'] = !ignore.includes(key)?(docKey.value?'none':'block'):'none'
//             }
//         } catch (err) {
//             continue
//         }

//         if (!docKey) {
//             continue
//         }

//         var label = docKey.dataset ? docKey.dataset['label'] : 0
//         var placeholder = docKey.placeholder
//         objectReturn.labelIsEmpty[key] = label ? label : placeholder ? placeholder : ''
                
//         if(!objectReturn.labelIsEmpty[key]){
//             if(alertLabel[key]){
//                 objectReturn.labelIsEmpty[key] = alertLabel[key]
//             }
//         }

//         data[key] = form.get(key)
//     }

//     objectReturn.labelArrayIsEmpty = Object.values(objectReturn.labelIsEmpty)
//     objectReturn.labelArrayIsEmpty = objectReturn.labelArrayIsEmpty.filter(label => label)

//     objectReturn.keys = Object.keys(objectReturn.keyIsEmpty ? objectReturn.keyIsEmpty : {})

//     objectReturn.keys.map((key) => {
//         objectReturn.stringIsEmpty += objectReturn.labelIsEmpty[key] + ', '
//     })

//     if (objectReturn.stringIsEmpty.length >= 2) {
//         objectReturn.stringIsEmpty = objectReturn.stringIsEmpty.substring(0, objectReturn.stringIsEmpty.length - 2)
//     }

//     let txtVacios = options.claveEtiquetas ? document.querySelectorAll(options.claveEtiquetas) : ''
//     if (txtVacios) {
//         for (let values of txtVacios.values()) {
//             values.style['display'] = 'none'
//         }

//         objectReturn.keys.map((key) => {
//             let txtKey = document.querySelector(`.${key}`)

//             if (txtKey) {
//                 txtKey.style['display'] = 'block'
//             }
//         })
//     }

//     let formEvent = (e) => {
//         try {
//             if (ignore.includes(e.target.id)) {
//                 return
//             }

//             var txt = document.getElementById(e.target.id)
//             var label = document.querySelector(`.${e.target.id}`)

//             if (!txt.value) {
//                 label.style['display'] = 'block'
//             } else {
//                 label.style['display'] = 'none'
//             }
//         } catch (err) {
//             return
//         }
//     }

//     elemet.addEventListener('keydown', formEvent)
//     elemet.addEventListener('keyup', formEvent)
//     elemet.addEventListener('keypress', formEvent)
//     elemet.addEventListener('change', formEvent)
//     elemet.addEventListener('input', formEvent)
//     elemet.addEventListener('paste', formEvent)

//     objectReturn.data = data
//     return objectReturn
// }

const getForm = (idForm, options = { ignore: [], active: 0, claveEtiquetas: '', alertLabel: {} }) => {
    if (idForm === '' || idForm === null || idForm === undefined) {
        return { status: 0 };
    }

    const elemet = document.getElementById(idForm);
    let objectReturn = { status: 0, data: {}, formIsEmpty: 0, labelIsEmpty: {}, stringIsEmpty: '', keyIsEmpty: {}, documentForm: elemet, keys: []};

    if (!elemet) {
        return { status: 0, data: {}, formIsEmpty: 0, labelIsEmpty: {}, keyIsEmpty: {} };
    }

    const form = new FormData(elemet);
    const data = {};
    const alertLabel = options.alertLabel || {};
    const ignore = JSON.stringify(options.ignore ? options.ignore : '');
    objectReturn = {
        ...objectReturn,
        formData: form,
        status: 1,
    };

    if (options.active) {
        return objectReturn;
    }

    for (let key of form.keys()) {
        let dataValue = document.getElementById(key).dataset['value'];
        if (!(dataValue == null || dataValue == undefined || dataValue === '')) {
            form.set(key, dataValue);
        }

        const trimmedValue = form.get(key)?.trim() || ''; // Aplica trim al valor del input

        if (trimmedValue === '') {
            if (!ignore.includes(key)) {
                objectReturn.formIsEmpty += 1;
                objectReturn.keyIsEmpty[key] = true;
            }
        }

        let docKey = null;

        try {
            docKey = elemet.querySelector(`[name="${key}"]`) || elemet.getElementById(key);
            let etiqueta = document.querySelector('.' + key);
            if (etiqueta) {
                etiqueta.style['display'] = !ignore.includes(key) ? (trimmedValue ? 'none' : 'block') : 'none';
            }
        } catch (err) {
            continue;
        }

        if (!docKey) {
            continue;
        }

        var label = docKey.dataset ? docKey.dataset['label'] : 0;
        var placeholder = docKey.placeholder;
        objectReturn.labelIsEmpty[key] = label ? label : placeholder ? placeholder : '';

        if (!objectReturn.labelIsEmpty[key]) {
            if (alertLabel[key]) {
                objectReturn.labelIsEmpty[key] = alertLabel[key];
            }
        }

        data[key] = trimmedValue; // Guarda el valor ya procesado con trim
    }

    objectReturn.labelArrayIsEmpty = Object.values(objectReturn.labelIsEmpty);
    objectReturn.labelArrayIsEmpty = objectReturn.labelArrayIsEmpty.filter(label => label);

    objectReturn.keys = Object.keys(objectReturn.keyIsEmpty ? objectReturn.keyIsEmpty : {});

    objectReturn.keys.map((key) => {
        objectReturn.stringIsEmpty += objectReturn.labelIsEmpty[key] + ', ';
    });

    if (objectReturn.stringIsEmpty.length >= 2) {
        objectReturn.stringIsEmpty = objectReturn.stringIsEmpty.substring(0, objectReturn.stringIsEmpty.length - 2);
    }

    let txtVacios = options.claveEtiquetas ? document.querySelectorAll(options.claveEtiquetas) : '';
    if (txtVacios) {
        for (let values of txtVacios.values()) {
            values.style['display'] = 'none';
        }

        objectReturn.keys.map((key) => {
            let txtKey = document.querySelector(`.${key}`);

            if (txtKey) {
                txtKey.style['display'] = 'block';
            }
        });
    }

    let formEvent = (e) => {
        try {
            if (ignore.includes(e.target.id)) {
                return;
            }

            var txt = document.getElementById(e.target.id);
            var label = document.querySelector(`.${e.target.id}`);

            if (!txt.value.trim()) {
                label.style['display'] = 'block';
            } else {
                label.style['display'] = 'none';
            }
        } catch (err) {
            return;
        }
    };

    elemet.addEventListener('keydown', formEvent);
    elemet.addEventListener('keyup', formEvent);
    elemet.addEventListener('keypress', formEvent);
    elemet.addEventListener('change', formEvent);
    elemet.addEventListener('input', formEvent);
    elemet.addEventListener('paste', formEvent);

    objectReturn.data = data;
    return objectReturn;
};


const subirImg = (inputs = {}, callback) => {
    try{
        if (!inputs.arr.length) {
            return callback({ datos: {}, index: 0 })
        }
    
        var dataImg = new FormData()
        dataImg.append('id', inputs.id)
    
        Object.keys(inputs.arr[inputs.index]).map((k) => {
            dataImg.append(k, inputs.arr[inputs.index][k])
        })

        fetch(inputs.ruta, {
            method: 'POST',
            body: dataImg
        })
        .then((response) => response.json())
        .then((resImg) => {
            callback({ datos: resImg, index: inputs.index + 1 })
        })
        .catch((err) => {
            callback({ datos: err, index: inputs.index + 1 })
        })
    }catch(err){
        console.log(err)
    }
}

const urlToObject= async (url, name)=> {
    try{
        const response = await fetch(url);
        const blob = await response.blob();
        // let name = blob.type.split('/')[1]
        // name = name === 'jpeg' ? 'jpg' : name
        const file = new File([blob], name, {type: blob.type});
        return file
    }catch(err){
        return null
    }
}

const convertImageToBase64 = (imgUrl, callback) => {
    const image = new Image();
    image.crossOrigin='anonymous';
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      ctx.drawImage(image, 0, 0);
      const dataUrl = canvas.toDataURL();
      callback && callback(dataUrl)
    }
    image.src = imgUrl;
}

const formatNumber = (value) => {
    return parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]
}



const calcularPesoVol = (longitud = 0, altura = 0, anchura = 0) => {
    let volumen = longitud * altura * anchura
    let factorPesoDim = 5000
    let pesoVol = volumen / factorPesoDim

    pesoVol = isNaN(pesoVol) || pesoVol === null || pesoVol === undefined?0:pesoVol

    return pesoVol
}

const local = {
    getObjectJson: (key) => {
        let object = localStorage.getItem(key)
    
        if (object) {
            return JSON.parse(object)
        } else {
            return null
        }
    },
    addObjectJson: (key, value) => {
        if(value === null || value === undefined){
            return
        }

        localStorage.setItem(key, JSON.stringify(value))
    },
    addItemObjectJson: (key, value) => {
        let object = localStorage.getItem(key)
        if (object) {
            object = JSON.parse(object)
        } else {
            object = []
        }

        let item = object.find((item) => (item.clave !== null && item.clave !== undefined) && (value.clave !== null && value.clave !== undefined) && item.clave == value.clave)

        if (item) {
            object = object.map((v) => v.clave == item.clave ? value : v)
        } else {
            object.push(value)
        }

        localStorage.setItem(key, JSON.stringify(object))
    },
    cargarObjeto: (objeto) => {
        let keysObject = Object.keys(objeto)
        keysObject.map((k) => {
            local.addObjectJson(k, objeto[k])
        })
    }
}

const moneyFormat = (value) => {
    return parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

function validarNumDec(input) {
    // Remover cualquier carácter no numérico o negativo
    input.value = input.value.replace(/[^0-9]/g, '');

    // Verificar si el número es positivo
    const numero = parseFloat(input.value);
}

function validarNumVersion(input) {
    // Elimina caracteres que no sean números o puntos
    input.value = input.value.replace(/[^0-9.]/g, '');

    // Evita múltiples puntos seguidos (.. o más)
    input.value = input.value.replace(/\.{2,}/g, '.');

    // Evita que empiece con un punto si es el primer carácter
    if (input.value.startsWith('.')) {
        input.value = '';
    }

    return input.value;
}

const validarCorreo = (correo) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(correo)
}

const aesEncrypt = (dato) => {
    const ciphertext = btoa(CryptoJS.AES.encrypt(String(dato), 'P4Mth25rQyB').toString());
    return ciphertext
}

const aesDecrypt = (ciphertext) => {
    const dato = CryptoJS.AES.decrypt(atob(ciphertext), 'P4Mth25rQyB');
    return dato.toString(CryptoJS.enc.Utf8)
}

function capitalizarPrimeraLetra(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

const validName = (e) => {
    let value = e.target.value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s´]/g, '');
    e.target.value = value
}

const formatoNumber=  (e) => {
    let value = e.target.value.replace(/\D/g, ''); 
    e.target.value = value
}

const formatoCoordenadas = (e) => {
    let value = e.target.value.replace(/[^0-9.-]/g, ''); 
      
    // Asegurarte de que solo haya un punto '.'
    const parts = value.split('.');
    if (parts.length > 2) {
        value = parts[0] + '.' + parts.slice(1).join(''); 
    }
    
    e.target.value = value;
};

function validMail(value) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(value)
}

function limitarCaracteres(input, caracteres) {
    if (input.length > caracteres) {
        return true
    }
    return false
}

export default { limitarCaracteres, validMail,validName,formatoNumber, formatoCoordenadas,validarNumDec, validarNumVersion, aesEncrypt, aesDecrypt, formatFechaLargaCompleta, validarCorreo, capitalizarPrimeraLetra, moneyFormat, local, urlClear, calcularPesoVol, urlToObject, formatNumber, convertImageToBase64, subirImg, getForm, send, format, toast, toolFetch, pathData, toFixed2, toFixed,  toZero, dateFormat, notNull, urlFormat, getGrafica, send2, swalModal};